import {strings} from '@app/common/strings';
import theme from '@app/themes';
import {Typography, Grid, Card, CardContent} from '@mui/material';
export const DailyCallReportBox = ({
  Key_Fields,
  Key_Values,
}: {
  Key_Fields: any;
  Key_Values: any;
}) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={6} direction="column">
            {Key_Fields?.map((field: any) => {
              return (
                <Typography mt={1} sx={{color: theme.colors.black}} key={field}>
                  {field ?? strings.moduleSpecificUtilities.noData}
                </Typography>
              );
            })}
          </Grid>
          <Grid item xs={6} direction="column">
            {Key_Values &&
              Object?.values(Key_Values)?.map((value: any) => (
                <Typography mt={1} sx={{color: theme.colors.black}} key={value}>
                  {value ?? strings.moduleSpecificUtilities.noData}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
