import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put, all} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {
  attachHierarchyToPlanAndMeetItem,
  attachHQstoRegions,
  createFilterObjectFromPreferredFilters,
  getMtpEditObject,
  getPlanAndMeetObject,
  getStpEditObject,
  selectDeselectHierarchyItems,
  sortRecommendations,
  transformFilters,
} from '@app/helper/plan-and-meet';

import {
  planAndMeetStatusStateActions,
  fetchPlanAndMeetListCreatorTypeName,
  updateMTPTypeName,
  editSTPStatusCreatorTypeName,
  fetchMTPRecommendationsCreatorTypeName,
  fetchHqsCreatorTypeName,
  filterBasedDownloadExcelCreatorTypeName,
  fetchPreferredFiltersCreatorTypeName,
  fetchMTPUnlockReasonsCreatorTypeName,
  unlockSTPMTPCreatorTypeName,
  enableDCRCreatorTypeName,
  fetchHierarchyCreatorTypeName,
  HierarchyBasedDownloadExcelCreatorTypeName,
  unlockGSPCreatorTypeName,
  bulkGSPUpdateCreatorTypeName,
  webAccessUnlockCreatorTypeName,
  webAccessStatusCreatorTypeName,
  stpBulkUpdateCreatorTypeName,
  mtpBulkUpdateCreatorTypeName,
  dcrBulkUpdateCreatorTypeName,
  fetchAllHierarchyCreatorTypeName,
  enableUnfilledDCRCreatorTypeName,
  stpBulkUnlockCreatorTypeName,
  mtpBulkUnlockCreatorTypeName,
  enableRealmUploadCreatorTypeName,
} from './slice';

import {planAndMeetSelector} from './selector';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {routeSelector} from '@app/router/redux';

/**
 * Employee Details Watcher
 */
export function* fetchPlanAndMeetStatusListWatcher() {
  yield takeLatest(
    fetchPlanAndMeetListCreatorTypeName,
    fetchPlanAndMeetListWorker,
  );
  yield takeLatest(editSTPStatusCreatorTypeName, editSTPStatus);
}

/**
 * MTP Status Watcher
 */
export function* updateMTPStatusWatcher() {
  yield takeLatest(updateMTPTypeName, updateMTPStatusWorker);
}

/**
 * MTP Status Watcher
 */
export function* fetchMTPRecommendationsWatcher() {
  yield takeLatest(
    fetchMTPRecommendationsCreatorTypeName,
    fetchMTPRecommendationsWorker,
  );
}

/**
 * HQs Watcher
 */
export function* fetchHqsWatcher() {
  yield takeLatest(fetchHqsCreatorTypeName, fetchHqsWorker);
}

/**
 * Download Excel Watcher
 */
export function* filterBasedDownloadExcelWatcher() {
  yield takeLatest(
    filterBasedDownloadExcelCreatorTypeName,
    filterBasedDownloadExcelWorker,
  );
}
export function* HierarchyBasedDownloadExcelWatcher() {
  yield takeLatest(
    HierarchyBasedDownloadExcelCreatorTypeName,
    HierarchyBasedDownloadExcelWorker,
  );
}

/**
 * Preferred Filters Watcher
 */
export function* fetchPreferredFiltersWatcher() {
  yield takeLatest(
    fetchPreferredFiltersCreatorTypeName,
    fetchPreferredFiltersWorker,
  );
}

/**
 * Preferred Filters Watcher
 */
export function* fetchMTPUnlockReasonsWatcher() {
  yield takeLatest(
    fetchMTPUnlockReasonsCreatorTypeName,
    fethMTPUnlockReasonsWorker,
  );
  yield takeLatest(unlockSTPMTPCreatorTypeName, unlockSTPMTPWorker);
}
/**
 * Enable DCR Watcher
 */
export function* enableDCRWatcher() {
  yield takeLatest(enableDCRCreatorTypeName, enableDCRWorker);
  yield takeLatest(unlockGSPCreatorTypeName, unlockGSPWorker);
}

/**
 * Single level hierarchy Watcher
 */
export function* fetchPlanAndMeetHierarchyWatcher() {
  yield takeLatest(
    fetchHierarchyCreatorTypeName,
    fetchPlanAndMeetHierarchyWorker,
  );
}

/**
 * All Hierarchy Watcher
 */
export function* fetchAllHierarchyWatcher() {
  yield takeLatest(fetchAllHierarchyCreatorTypeName, fetchAllHierarchyWorker);
}

/**
 * Bulk GSP Watcher
 */
export function* BulkGSPUpdateWatcher() {
  yield takeLatest(bulkGSPUpdateCreatorTypeName, BulkGSPUpdateWorker);
}

/**
 * Bulk GSP Watcher
 */
export function* webAccessUnlockWatcher() {
  yield takeLatest(webAccessUnlockCreatorTypeName, webAccessUnlockWorker);
}

/**
 * Web Access Status
 */
export function* getWebAccessStatusWatcher() {
  yield takeLatest(webAccessStatusCreatorTypeName, getWebAccessStatusWorker);
}

/**
 * STP Bulk Update Status
 */
export function* bulkSTPUpdateWatcher() {
  yield takeLatest(stpBulkUpdateCreatorTypeName, getBulkSTPUpdateWorker);
}

/**
 * MTP bulk Update Status
 */
export function* bulkMTPUpdateWatcher() {
  yield takeLatest(mtpBulkUpdateCreatorTypeName, getBulkMTPUpdateWorker);
}

/**
 * MTP bulk Update Status
 */
export function* bulkDCRPUpdateWatcher() {
  yield takeLatest(dcrBulkUpdateCreatorTypeName, getBulkDCRUpdateWorker);
}

/**
 * Enable Unfilled DCR Watcher
 */
export function* enableUnfilledDCRWatcher() {
  yield takeLatest(enableUnfilledDCRCreatorTypeName, enableUnfilledDCRWorker);
}

/**
 * STP Bulk Update Status
 */
export function* bulkSTPUnlockWatcher() {
  yield takeLatest(stpBulkUnlockCreatorTypeName, getBulkSTPUnlockWorker);
}

/**
 * MTP Bulk Update Status
 */
export function* bulkMTPUnlockWatcher() {
  yield takeLatest(mtpBulkUnlockCreatorTypeName, getBulkMTPUnlockWorker);
}

/**
 * Enable Realm Upload Watcher
 */
export function* enableRealmUploadWatcher() {
  yield takeLatest(enableRealmUploadCreatorTypeName, enableRealmUploadWorker);
}

/**
 * Employee Details Worker
 */
export function* fetchPlanAndMeetListWorker(action?: any): any {
  const {isRoleTLM, month, year} = action.payload || {};
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const request = isRoleTLM
    ? {...getPlanAndMeetObject(obj), year, month}
    : getPlanAndMeetObject(obj);
    const url = API_PATH.planAndMeetStatus.fetchPlanAndMeetList;
    const recordUrl = API_PATH.planAndMeetStatus.fetchPlanAndMeetListRecord;

    const response = yield call(NetworkService.post, url, request);
    if (response?.status === HTTP_OK) {
      const selectedRoute = yield select(
        routeSelector.getNavbarComponentName(),
      );
      if (selectedRoute === navbarComponentName.planAndMeet) {
        const updatedFilters = transformFilters(
          response?.data?.filterSummary,
          obj?.filters,
        );
        yield put(filterStateActions.setFilterState(updatedFilters));
      }
      yield put(
        planAndMeetStatusStateActions.setPlanAndMeetStatusList(
          response.data?.gridData,
        ),
      );
      yield put(
        planAndMeetStatusStateActions.setTotalRowsInGrid(
          response.data?.recordsCount?.[0]?.totalRecordCount,
        ),
      );
      yield put(
        planAndMeetStatusStateActions.setTotalGSPUnlockEligibleCount(
          response.data?.recordsCount?.[0].totalGSPUnlockEligibleCount,
        ),
      );
      yield put(
        planAndMeetStatusStateActions.setTotalGSPUnlockNotEligibleCount(
          response.data?.recordsCount?.[0]?.totalGSPUnlockNotEligibleCount,
        ),
      );
      yield put(
        planAndMeetStatusStateActions.setTotalWebUnlockEligibleCount(
          response.data?.recordsCount?.[0].totalWebAccessUnlockEligibleCount,
        ),
      );
      yield put(
        planAndMeetStatusStateActions.setTotalWebUnlockNotEligibleCount(
          response.data?.recordsCount?.[0]
            ?.totalWebAccessUnlockNotEligibleCount,
        ),
      );
      yield put(
        planAndMeetStatusStateActions.setAllStaffPositionIds(
          response.data?.staffPositionIds,
        ),
      );
    }
    yield put(appSliceActions.setAppLoading(false));
  } catch (error) {}
}

/**
 * MTP Status Worker
 */
export function* updateMTPStatusWorker(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const url = API_PATH.planAndMeetStatus.updateMTPStatus;

    const request = getMtpEditObject(
      obj,
      action?.payload?.data,
      action?.payload?.mtpStatus,
    );

    const response = yield call(NetworkService.post, url, request);
    yield put(
      planAndMeetStatusStateActions.setMTPEditResponse({
        ...response.data,
        empName: action?.payload.empName,
        status: action?.payload?.mtpStatus,
      }),
    );
  } catch (error) {
    const errorMessage = {
      errorCode: null,
      description: '',
      details: [{message: 'Something went wrong while editing MTP'}],
    };
    yield put(planAndMeetStatusStateActions.setMTPEditResponse(errorMessage));
  }
}

/**
 * STP Status Worker
 */
export function* editSTPStatus(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const request = getStpEditObject(obj, action.payload);
    const url = API_PATH.planAndMeetStatus.editStpStatus;
    const response = yield call(NetworkService.post, url, request);
    yield put(
      planAndMeetStatusStateActions.setSTPEditResponse({
        ...response.data,
        empName: action?.payload.empName,
      }),
    );
  } catch (error) {
    const errorMessage = {
      errorCode: null,
      description: '',
      details: [{message: 'Something went wrong while editing access'}],
    };
    yield put(planAndMeetStatusStateActions.setSTPEditResponse(errorMessage));
  }
}

/**
 * fetch recommendation message for mtp
 */
export function* fetchMTPRecommendationsWorker(action: any): any {
  try {
    const data = action?.payload;
    const url = `${API_PATH.planAndMeetStatus.mtpRecommendations}/${data?.staffPositionId}/${data?.isCurrentMonth}`;
    const response = yield call(NetworkService.get, url, {}, {});
    const sortedRecommendations = sortRecommendations(response?.data);
    yield put(
      planAndMeetStatusStateActions.setRecommendations(sortedRecommendations),
    );
  } catch (error) {
    yield put(planAndMeetStatusStateActions.setRecommendations(null));
  }
}

/**
 * fetch hqs
 */
export function* fetchHqsWorker(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const filters = yield select(filterSelector.getFilterState());
    const request = getPlanAndMeetObject(obj);
    const body = {
      ...request,
      pageSize: 0,
    };
    const url = `${API_PATH.planAndMeetStatus.hqsByRegion}?regionId=${action.payload.regionId}`;
    const response = yield call(NetworkService.post, url, body);
    const updatedFilters = attachHQstoRegions(
      filters,
      action.payload.regionId,
      action.payload.zoneId,
      response?.data,
      obj?.filters,
    );
    yield put(filterStateActions.setFilterState(updatedFilters));
  } catch (error) {
    yield put(filterStateActions.setFilterState({}));
  }
}
export function* filterBasedDownloadExcelWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.downloadExcel;
    const response = yield call(NetworkService.post, url, action?.payload);
    if (response.status == HTTP_OK) {
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
      const outputFilename = `plan_and_meet_${Date.now()}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {}
}

/**
 * fetch preferred filters
 */
export function* fetchPreferredFiltersWorker(): any {
  try {
    const url = API_PATH.planAndMeetStatus.preferredFilters;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      const appliedFilters = createFilterObjectFromPreferredFilters(
        response?.data,
      );
      yield put(planAndMeetStatusStateActions.setFilters(appliedFilters));
      yield put(
        planAndMeetStatusStateActions.setPreferredFilters(response?.data),
      );
    } else {
      yield put(planAndMeetStatusStateActions.setFilters({}));
      yield put(planAndMeetStatusStateActions.setPreferredFilters({}));
    }
  } catch (error) {
    yield put(filterStateActions.setFilterState({}));
  }
}

/**
 * fetch mtp unlock reason
 */
export function* fethMTPUnlockReasonsWorker(): any {
  try {
    const url = API_PATH.planAndMeetStatus.mtpUnlockReasons;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setMTPUnlockReasons(response?.data),
      );
    }
  } catch (error) {}
}

/**
 * Unlock STP MTP worker
 */
export function* unlockSTPMTPWorker(action: any): any {
  try {
    const {isCurrentMonth} = action?.payload?.body;
    const url = isCurrentMonth
      ? API_PATH.planAndMeetStatus.unlockstpbyadmin
      : API_PATH.planAndMeetStatus.unlockmtpbyadmin;
    const response = yield call(
      NetworkService.post,
      url,
      action?.payload?.body,
    );
    yield put(
      planAndMeetStatusStateActions.setSTPMTPUnlockResponse({
        ...response?.data,
        empName: action?.payload?.empName,
        type: isCurrentMonth ? 'STP' : 'MTP',
      }),
    );
  } catch (error) {
    const errorMessage = {
      errorCode: null,
      description: '',
      details: [{message: 'Something went wrong while editing access'}],
    };
    yield put(
      planAndMeetStatusStateActions.setSTPMTPUnlockResponse(errorMessage),
    );
  }
}

/**
 * Enable DCR Worker
 */
export function* enableDCRWorker(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());

    const IsSTPDCREnable =
      action?.payload?.submitSTP && !action?.payload?.submitMTP;

    let response;

    if (action?.payload?.submitSTP) {
      const stpRequest = getStpEditObject(
        obj,
        action?.payload?.data,
        true,
        IsSTPDCREnable,
      );
      const stpUrl = API_PATH.planAndMeetStatus.editStpStatus;
      response = yield call(NetworkService.post, stpUrl, stpRequest);
    }

    if (action?.payload?.submitMTP) {
      const mtpRequest = getMtpEditObject(
        obj,
        action?.payload?.data,
        action?.payload?.mtpStatus,
        true,
      );
      const mtpUrl = API_PATH.planAndMeetStatus.updateMTPStatus;
      response = yield call(NetworkService.post, mtpUrl, mtpRequest);
    }

    yield put(
      planAndMeetStatusStateActions.setDCREditResponse({
        ...response?.data,
        empName: action?.payload.empName,
      }),
    );
  } catch (error) {
    const errorMessage = {
      errorCode: null,
      description: '',
      details: [{message: 'Something went wrong while editing MTP'}],
    };
    yield put(planAndMeetStatusStateActions.setMTPEditResponse(errorMessage));
  }
}
/**
 * Unlock GSP Worker
 */
export function* unlockGSPWorker(action: any): any {
  try {
    const request = {
      staffPositionId: action?.payload?.staffPositionId,
    };
    const gspUnlockUrl = API_PATH.planAndMeetStatus.unlockgspbyadmin;
    const gspUnlockResponse = yield call(
      NetworkService.post,
      gspUnlockUrl,
      request,
    );

    if (gspUnlockResponse?.status == 200) {
      yield put(
        planAndMeetStatusStateActions.setGSPUnlockResponse({
          success: gspUnlockResponse?.data,
          empName: action?.payload?.empName,
        }),
      );
    } else {
      const errorMessage = {
        errorCode: null,
        description: '',
        details: [{message: gspUnlockResponse?.data?.details[0]?.message}],
      };
      yield put(
        planAndMeetStatusStateActions.setGSPUnlockResponse(errorMessage),
      );
    }
  } catch (error) {
    const errorMessage = {
      errorCode: null,
      description: '',
      details: [{message: 'Something went wrong while unlocking GSP'}],
    };
    yield put(planAndMeetStatusStateActions.setGSPUnlockResponse(errorMessage));
  }
}
/**
 * Fetch Plan and Meet Hierarchy
 */
export function* fetchPlanAndMeetHierarchyWorker(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const request = getPlanAndMeetObject(obj);
    const staffPositionId = action?.payload?.staffPositionId;
    const isCurrentMonth = request?.isCurrentMonth;

    const url = `${API_PATH.planAndMeetStatus.fetchPlanAndMeetHierarchy}/${staffPositionId}/${isCurrentMonth}`;
    const response = yield call(NetworkService.get, url, {}, {});

    const planAndMeetList = obj?.planAndMeetStatusList;

    const updatedList = attachHierarchyToPlanAndMeetItem(
      planAndMeetList,
      response.data,
      staffPositionId,
      action?.payload?.isInnerChild,
    );

    yield put(
      planAndMeetStatusStateActions.setPlanAndMeetStatusList(updatedList),
    );
  } catch (error) {
    console.error(error);
  }
}

/**
 * Fetch All Hierarchy
 */
export function* fetchAllHierarchyWorker(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const request = getPlanAndMeetObject(obj);
    const staffPositionId = action?.payload?.staffPositionId;
    const roleId = action?.payload?.roleId;
    const isCurrentMonth = request?.isCurrentMonth;

    const url = `${API_PATH.planAndMeetStatus.fetchBulkHierarchy}/${staffPositionId}/${isCurrentMonth}?roleId=${roleId}`;
    const response = yield call(NetworkService.get, url, {}, {});

    yield put(
      planAndMeetStatusStateActions.setAllHierarchyData(response?.data),
    );
    if (action?.payload?.updateState) {
      const preselectedRows = yield select(
        planAndMeetSelector.getSelectedRows(),
      );
      const updatedRows = selectDeselectHierarchyItems(
        action?.payload?.selectAll,
        preselectedRows,
        response?.data,
      );
      yield put(planAndMeetStatusStateActions.setSelectedRows(updatedRows));
      yield put(planAndMeetStatusStateActions.setIsAtHierarchyLevel(true));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* HierarchyBasedDownloadExcelWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.downloadExcelHierarchyBased;
    const response = yield call(
      NetworkService.post,
      url,
      action?.payload?.body,
    );
    if (response.status == HTTP_OK) {
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
      const outputFilename = `${action?.payload?.filename}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {}
}

export function* BulkGSPUpdateWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.bulkGSPUpdate;
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const response = yield call(NetworkService.post, url, action.payload);

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setBulkUpdateSummaryList(response?.data),
      );
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {}
}

export function* webAccessUnlockWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.webAccessUnlock;
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const response = yield call(NetworkService.post, url, action.payload.body);

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setBulkUpdateSummaryList(response?.data),
      );
      if (action.payload?.empName) {
        yield put(
          planAndMeetStatusStateActions.setWebAccessResponse({
            status: response?.data,
            empName: action.payload?.empName,
            isLockedRequest: action.payload?.body?.isLockedRequest,
          }),
        );
      }
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {}
}

export function* getWebAccessStatusWorker(action: any): any {
  try {
    const url = `${API_PATH.main.webAccessStatus}?staffpositionId=${action?.payload?.staffPositionId}`;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setWebAccessStatus(response?.data),
      );
    }
  } catch (error) {}
}

export function* getBulkSTPUpdateWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.bulkSTPUpdate;
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const response = yield call(NetworkService.post, url, action.payload);

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setBulkUpdateSummaryList(response?.data),
      );
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {}
}

export function* getBulkMTPUpdateWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.bulkMTPUpdate;
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const response = yield call(NetworkService.post, url, action.payload);

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setBulkUpdateSummaryList(response?.data),
      );
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {}
}

export function* getBulkDCRUpdateWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.bulkDCRUpdate;
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const response = yield call(NetworkService.post, url, action.payload);

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setBulkUpdateSummaryList(response?.data),
      );
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {}
}

/**
 * Enable Unfilled DCR Worker
 */
export function* enableUnfilledDCRWorker(action: any): any {
  try {
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const url = API_PATH.planAndMeetStatus.enableUnfilledDCR;
    const response = yield call(NetworkService.post, url, action.payload.body);
    if (response.status === HTTP_OK) {
      if (action.payload?.isBulkUpdate) {
        yield put(
          planAndMeetStatusStateActions.setBulkUpdateSummaryList(
            response?.data,
          ),
        );
      } else {
        yield put(
          planAndMeetStatusStateActions.setUnfilledDCRResponse({
            response: response?.data,
            empName: action.payload.empName,
          }),
        );
      }
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
    yield put(planAndMeetStatusStateActions.setUnfilledDCRResponse(null));
  }
}

export function* getBulkSTPUnlockWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.bulkStpUnlock;
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const response = yield call(NetworkService.post, url, action.payload);

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setBulkUpdateSummaryList(response?.data),
      );
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {}
}

export function* getBulkMTPUnlockWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.bulkMtpUnlock;
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(true));
    const response = yield call(NetworkService.post, url, action.payload);

    if (response?.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setBulkUpdateSummaryList(response?.data),
      );
    }
    yield put(planAndMeetStatusStateActions.setBulkGSPUnlockLoader(false));
  } catch (error) {}
}

/**
 * Enable Realm Upload Worker
 */
export function* enableRealmUploadWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.enableRealmUpload;
    const response = yield call(NetworkService.put, url, action.payload.body);
    if (response.status === HTTP_OK) {
      yield put(
        planAndMeetStatusStateActions.setRealmUploadResponse({
          status: true,
          empName: action?.payload?.empName,
        }),
      );
    }
  } catch (error) {
    yield put(planAndMeetStatusStateActions.setRealmUploadResponse(null));
  }
}
