import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {LedgerReportstyles as styles} from './styles';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {nanoid} from '@reduxjs/toolkit';
import {Search} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchAutoSuggestListForItemsCreator,
  fetchAutoSuggestListForStaffNameCreator,
  fetchLedgerReportDataCreator,
  inventoryModuleLedgerActions,
} from '@app/screens/inventory-management/pages/ledgerReport/redux/slice';
import {debounce} from 'lodash';
import {inventoryLedgerSelector} from '@app/screens/inventory-management/pages/ledgerReport/redux/selectors';
import exportDataToPDF from '@app/components/ExportAsPDF';
import ExcelPdfReport from '@app/components/ExportAsExcel';

export enum SearchType {
  items = 'item',
  sample = 'sample',
}
const searchTypeLabel = {
  item: 'Items',
  sample: 'Samples',
};
interface Item {
  label: string;
  value: number;
}

const columnHeaders = [
  {id: 1, name: 'transactionDetails', title: 'Transaction Details'},
  {id: 2, name: 'itemSampleName', title: 'Sample/Item Name'},
  {id: 4, name: 'transactionDate', title: 'Transaction Date'},
  {id: 9, name: 'challanQuantity', title: 'In-Transit Challan Qty'},
  {id: 7, name: 'shortQuantity', title: 'Short Qty'},
  {id: 5, name: 'receivedQuantity', title: 'Received Qty'},
  {id: 8, name: 'usedQuantity', title: 'Used Qty'},
  {id: 6, name: 'transferedQuantity', title: 'Tansferred  Qty'},
  {id: 3, name: 'totalBalance', title: 'Total Balance'},
];
const titleHeader = [
  {
    id: 1,
    title: 'Name',
  },
];

const initial = {
  userId: 0,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
      overflow: 'auto',
    },
  },
};

export const LedgerReportNavbar = ({
  stDate = null,
  user = initial,
  search = '',
  selectedSample = [],
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(stDate);
  const [openSearch1, setOpenSearch1] = useState(false);
  const [searchType, setSearchType] = useState<string>(search);
  const [disabled, setDisabled] = useState(false);
  const [searchText1, setSearchText1] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState(user);
  const [autoCompleteValueForStaff, setAutoCompleteValueForStaff] = useState<
    any[]
  >([]);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [selectedItemOrSample, setSelectedItemOrSample] = useState<Item[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const selectAllOption = {label: 'Select All'};
  const [selectedItemOrSampleName, setSelectedItemOrSampleName] = useState('');

  const todayDate = dayjs();

  const getLedgerData = useSelector(
    inventoryLedgerSelector.getLedgerReportData(),
  );
  const getAutoSuggestForStaffName = useSelector(
    inventoryLedgerSelector.getAutoSuggestListForStaff(),
  );

  const getItemsOrSamplesList = useSelector(
    inventoryLedgerSelector.getAutoSuggestListForItems(),
  );

  const payloadData = useSelector(
    inventoryLedgerSelector.getLedgerPayloadData(),
  );

  const ledgerReportDownloadData = useSelector(
    inventoryLedgerSelector.getLedgerReportExcelData(),
  );
  const handleEffectiveDate = (date: any) => {
    setStartDate(date);
  };

  useEffect(() => {
    let autoSuggest: any[] = [];
    if (getAutoSuggestForStaffName?.length > 0) {
      getAutoSuggestForStaffName.forEach((element: any) => {
        if (!autoSuggest.includes(element.displayData)) {
          autoSuggest.push(element.displayData);
        }
      });
    }
    setAutoCompleteValueForStaff(autoSuggest);
  }, [getAutoSuggestForStaffName]);

  useEffect(() => {
    const staff = getAutoSuggestForStaffName?.filter(
      (item: any) => item.displayData === searchText1,
    );
    staff?.length > 0 && setSelectedUser(staff[0]);
  }, [searchText1]);

  const onTextFieldChange = (e: any) => {
    setSearchText1(e.target.value);
    debouncedAutoSuggest(e.target.value);
    setStartDate(null);
    setSearchType('');
    dispatch(inventoryModuleLedgerActions.setLedgerReportData([]));
  };
  useEffect(() => {
    setSelectedItemOrSample([]);
  }, [searchText1]);

  const handleDDChange = (e: any) => {
    setSearchType(e.target.value);
    setSelectedItemOrSample([]);
    setSelectAllChecked(false);
  };

  const debouncedAutoSuggest = debounce((newval: string) => {
    if (newval.length >= 3) {
      dispatch(
        fetchAutoSuggestListForStaffNameCreator({
          searchText: newval || '',
          isActive: true,
        }),
      );
    }
  }, 1000);

  useEffect(() => {
    return () => {
      dispatch(inventoryModuleLedgerActions.setLedgerReportData([]));
    };
  }, []);

  useEffect(() => {
    if (searchType && selectedUser?.userId !== 0 && startDate) {
      dispatch(
        fetchAutoSuggestListForItemsCreator({
          searchType,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(todayDate).format('YYYY-MM-DD'),
          userId: selectedUser?.userId,
        }),
      );
    }
  }, [searchType, selectedUser, startDate]);

  useEffect(() => {
    if (
      searchType &&
      selectedUser?.userId !== 0 &&
      startDate &&
      todayDate &&
      selectedItemOrSample?.length !== 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [searchType, selectedUser, startDate, selectedItemOrSample]);

  useEffect(() => {
    if (pdfLoader && ledgerReportDownloadData.length > 0) {
      genratePDF();
    }
  }, [ledgerReportDownloadData, pdfLoader]);

  const callback = () => {
    setPdfLoader(false);
    resetLedgerDownloadData();
  };
  const staffName = Array.isArray(ledgerReportDownloadData)
    ? ledgerReportDownloadData.map((item: any) => item?.staffname)
    : [];
  const division = Array.isArray(ledgerReportDownloadData)
    ? ledgerReportDownloadData.map((item: any) => item?.divisionName)
    : [];
  const designation = Array.isArray(ledgerReportDownloadData)
    ? ledgerReportDownloadData.map((item: any) => item?.designationName)
    : [];
  const hqName = Array.isArray(ledgerReportDownloadData)
    ? ledgerReportDownloadData.map((item: any) => item?.hqName)
    : [];
  const SapCode = Array.isArray(ledgerReportDownloadData)
    ? ledgerReportDownloadData.map((item: any) => item?.sapcode)
    : [];

  const genratePDF = () => {
    exportDataToPDF(
      columnHeaders,
      ledgerReportDownloadData,
      `Ledger Reports_${staffName[0]}_${SapCode[0]}`,
      callback,
      selectedItemOrSampleName,
    );
  };

  const handleApiCall = () => {
    const payload = {
      userId: selectedUser?.userId,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(todayDate).format('YYYY-MM-DD'),
      pageNumber: 1,
      offsetValue: 1000,
      isSample: searchType === 'sample',
      searchValueItemSamples:
        selectedItemOrSample?.length > 0
          ? selectedItemOrSample?.map((item: any) => item.value)
          : [],
    };
    dispatch(fetchLedgerReportDataCreator(payload));
    dispatch(inventoryModuleLedgerActions.setLedgerPayloadData(payload));
    dispatch(inventoryModuleLedgerActions.setPageChange(0));
  };
  const disableFutureDates = () => {
    return todayDate.isAfter(dayjs(), 'day'); // Disable dates that are after today
  };
  const ExcelDataAPI = () => {
    const selectedItemName =
      selectedItemOrSample?.length > 0
        ? selectedItemOrSample.map((item: any) => item.label)
        : [];
    setSelectedItemOrSampleName(
      selectedItemName?.length > 0 ? selectedItemName.join(' , ') : '',
    );
    const excelDataPayload = {
      userId: payloadData?.userId,
      startDate: payloadData?.startDate,
      endDate: payloadData?.endDate,
      pageNumber: 1,
      offsetValue: 1000,
      isSample: searchType === 'sample',
      searchValueItemSamples: payloadData?.searchValueItemSamples,
      apiFor: 'Download',
    };
    dispatch(fetchLedgerReportDataCreator(excelDataPayload));
  };

  const apiCallForPDF = () => {
    setPdfLoader(true);
    ExcelDataAPI();
  };

  const resetLedgerDownloadData = () => {
    dispatch(inventoryModuleLedgerActions.setLedgerReportExcelData([]));
  };

  const handleDDDChange = (event: any, newValue: any) => {
    if (newValue.includes(selectAllOption)) {
      if (selectedItemOrSample?.length !== getItemsOrSamplesList?.length) {
        setSelectedItemOrSample(getItemsOrSamplesList);
        setSelectAllChecked(true);
      } else {
        setSelectedItemOrSample([]);
        setSelectAllChecked(false);
      }
    } else {
      setSelectedItemOrSample(newValue);
      setSelectAllChecked(newValue?.length === getItemsOrSamplesList?.length);
    }
  };
  const optionsWithSelectAll = [
    ...(getItemsOrSamplesList && getItemsOrSamplesList?.length > 0
      ? [selectAllOption]
      : []),
    ...(getItemsOrSamplesList || []),
  ];

  return (
    <Box sx={styles.containerCNF}>
      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: 'space-between',
        }}>
        <Typography
          data-testid={'Pendency Report'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          Ledger Report
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          alignContent="center"
          marginRight={1.7}>
          <ExcelPdfReport
            staff={staffName[0]}
            division={division[0]}
            designation={designation[0]}
            hqName={hqName[0]}
            sap={SapCode[0]}
            searchedItem={selectedItemOrSampleName}
            data={ledgerReportDownloadData}
            fileName={`Ledger Report_${staffName[0]}_${SapCode[0]}`}
            headerTitle={titleHeader}
            header={columnHeaders}
            title={'Download As Excel'}
            onClick={() => ExcelDataAPI()}
            resetFun={() => resetLedgerDownloadData()}
            disableButton={
              getLedgerData?.reportData
                ? getLedgerData?.reportData?.length === 0
                : true
            }
          />
          <Box>
            <Button
              data-testid="PDF_Button"
              variant="contained"
              size="small"
              disabled={
                getLedgerData?.reportData
                  ? getLedgerData?.reportData?.length === 0
                  : true
              }
              sx={{textTransform: 'none', height: 30, left: 5, width: 140}}
              onClick={() => apiCallForPDF()}>
              {pdfLoader ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                'Download As PDF'
              )}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box display={'inline-flex'} flexDirection={'row'} paddingTop={3}>
        <Box display={'inline-flex'} flex={'auto'} flexDirection={'column'}>
          <div>
            Search By <span style={{color: 'red'}}>*</span>
          </div>

          <Box sx={styles.container2}>
            <FormControl sx={styles.formControl} fullWidth>
              <Autocomplete
                data-testid="searchText"
                key={'key'}
                open={openSearch1}
                sx={{width: '100%'}}
                onChange={(event, newval) => {
                  if (newval) {
                    setSearchText1(newval);
                    debouncedAutoSuggest(newval);
                  } else {
                    setSearchText1('');
                    dispatch(
                      inventoryModuleLedgerActions.setAutoSuggestListofStaff(
                        [],
                      ),
                    );
                    setSelectedUser(initial);
                  }
                }}
                onOpen={() => {
                  setOpenSearch1(true);
                }}
                onClose={() => {
                  setOpenSearch1(false);
                }}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option === value;
                }}
                options={autoCompleteValueForStaff || []}
                renderInput={(params: any) => (
                  <TextField
                    data-testid="test-texfield"
                    onChange={onTextFieldChange}
                    {...params}
                    placeholder={'Search by SAP Code/Name'}
                    sx={styles.textField}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" sx={styles.padding5}>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
        </Box>
        <div>
          <div
            style={
              getLedgerData?.reportData?.length > 0
                ? styles.marginLeftLabel
                : styles.marginLeftZero
            }>
            Start Date <span style={{color: 'red'}}>*</span>
          </div>
          <Box
            sx={
              getLedgerData?.reportData?.length > 0
                ? styles.container3
                : styles.container2
            }>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="YYYY/MM/DD"
                key={nanoid()}
                disabled={searchText1.length === 0}
                onChange={handleEffectiveDate}
                maxDate={dayjs().format('YYYY/MM/DD')}
                renderInput={params => {
                  return (
                    <TextField
                      data-testid="startDate"
                      {...params}
                      sx={[styles.startDateStyle, styles.paddingForMUIInput]}
                      error={false}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'YYYY/MM/DD',
                      }}
                      onInput={e => {
                        e.preventDefault(); // Prevent manual input
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </Box>
        </div>
        <div>
          <div
            style={
              getLedgerData?.reportData?.length > 0
                ? styles.marginLeftLabel
                : styles.marginLeftZero
            }>
            End Date <span style={{color: 'red'}}>*</span>
          </div>
          <Box
            sx={
              getLedgerData?.reportData?.length > 0
                ? styles.container3
                : styles.container2
            }>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="YYYY/MM/DD"
                value={todayDate}
                onChange={date => console.log(date)} // Handle date change as needed
                disableFuture={true} // Disable future dates
                shouldDisableDate={disableFutureDates}
                disabled={true}
                renderInput={params => {
                  return (
                    <TextField
                      data-testid="endDate"
                      sx={[styles.endDateStyle, styles.paddingForMUIInput]}
                      {...params}
                      error={false}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'YYYY/MM/DD',
                      }}
                      onInput={e => {
                        e.preventDefault(); // Prevent manual input
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </Box>
        </div>
      </Box>
      <Box
        sx={styles.formContainer}
        display={'inline-flex'}
        flexDirection={'row'}>
        <div style={{width: '100%'}}>
          <div>
            Item Type <span style={{color: 'red'}}>*</span>
          </div>
          <Box sx={styles.container}>
            <FormControl sx={styles.formControl} fullWidth>
              <Select
                inputProps={{
                  'data-testid': 'dropdown-search-type',
                }}
                variant="outlined"
                labelId="search-type-select-label"
                sx={[styles.itemTypeStyle, styles.paddingForMUIInput]}
                disabled={searchText1.length === 0 || startDate === null}
                value={searchType}
                onChange={handleDDChange}>
                <MenuItem value={SearchType.items}>
                  {searchTypeLabel[SearchType.items]}
                </MenuItem>
                <MenuItem value={SearchType.sample}>
                  {searchTypeLabel[SearchType.sample]}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={styles.marginForSearch}>
          <div style={{marginLeft: 0}}>
            Search By <span style={{color: 'red'}}>*</span>
          </div>
          <Box sx={styles.containerSearch}>
            <FormControl
              sx={{
                ...styles.formControl,
              }}>
              <Autocomplete
                sx={{backgroundColor: '#ffffff', width: '100%'}}
                multiple
                disableCloseOnSelect
                options={optionsWithSelectAll}
                value={selectedItemOrSample}
                getOptionLabel={option => option?.label ?? ''}
                onChange={handleDDDChange}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    {searchType && getItemsOrSamplesList.length && (
                      <Checkbox
                        checked={
                          selectedItemOrSample.includes(option) ||
                          (selectAllChecked && option === selectAllOption)
                        }
                        onChange={event => {
                          if (option === selectAllOption) {
                            handleDDDChange(
                              null,
                              selectAllChecked ? [] : optionsWithSelectAll,
                            );
                            setSelectAllChecked(!selectAllChecked);
                          } else {
                            const newValue = selectedItemOrSample.includes(
                              option,
                            )
                              ? selectedItemOrSample.filter(
                                  item => item !== option,
                                )
                              : [...selectedItemOrSample, option];
                            handleDDDChange(null, newValue);
                          }
                        }}
                      />
                    )}

                    {option?.label}
                  </li>
                )}
                renderTags={() => null}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={
                      selectedItemOrSample?.length === 0
                        ? 'Select or type to search'
                        : selectedItemOrSample
                            .map((item: Item) => item?.label)
                            .join(', ')
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-end',
            height: '100%',
            marginLeft: 10,
          }}>
          <Button
            data-testid="Search_Button"
            variant="contained"
            size="small"
            onClick={() => handleApiCall()}
            disabled={disabled}
            sx={{
              textTransform: 'none',

              width: 50,
              height: 53,
              marginRight: '15px',
            }}>
            Search
          </Button>
        </div>
      </Box>
    </Box>
  );
};
