import { API_PATH } from "@app/common/constants";
import { NetworkService, ToasterService } from "@app/services";
import { ToastType } from "@app/services/toaster";
import { takeLatest, put ,call} from "redux-saga/effects";
import { fetchExpenseParameterCreatorTypeName, expenseStateActions, fetchDownloadCreatorTypeName, fileUploadCreatorTypeName, fetchExpenseFileNumberTypeName, downloadExpenseFileNumberTempTypeName } from "./slice";

/**
 * Employee Details Watcher
 */
 export function* fetchExpenseParameterDataWatcher() {
    yield takeLatest(
        fetchExpenseParameterCreatorTypeName,
      fetchExpenseParameterDataWatcherWorker,
    );
  }
  /**
   * Employee Details Worker
   */
  export function* fetchExpenseParameterDataWatcherWorker(): any {
    try {
        const url = `${API_PATH.expense.parameter}`;
        const parameter = yield call(NetworkService.get, url, {}, {});
        yield put(
            expenseStateActions.setParameter(parameter?.data),
        );
    } catch (error) {
      ToasterService.showToaster(
        'Something went wrong while fetching expense parameter!',
        ToastType.ERROR,
      );
    }
  }

  /**
 * Employee Details Watcher
 */
 export function* fileUploadrDataWatcher() {
  yield takeLatest(
    fileUploadCreatorTypeName,
    fileUploadDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fileUploadDataWatcherWorker(action:any): any {
  try {
      const url = `${API_PATH.expense.upload}`;
      let data=action.payload
     let responsedata= yield call(NetworkService.postUploadFile, url, data);
      yield put(
          expenseStateActions.setUploadResponse(responsedata?.data),
      );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while upload the file!',
      ToastType.ERROR,
    );
  }
}
   /**
 * Employee Details Watcher
 */
 export function* fetchDownloadWatcher() {
    yield takeLatest(
        fetchDownloadCreatorTypeName,
      fetchDownloadnWatcherWorker,
    );
  }
  
  /**
   * Employee Details Worker
   */
  export function* fetchDownloadnWatcherWorker(action: any): any {
    try {
        let parameter = action.payload;
      const url = `${API_PATH.expense.download}${parameter}&returnInBase64String=true`;
      const dwonload = yield call(NetworkService.get, url, {}, {
      });
      yield put(
        expenseStateActions.setDownload(dwonload?.data),
      );
    } catch (error) {}
  }
     /**
 * Employee Details Watcher
 */
 export function*  downloadExpenseFileNumberTempWatcher() {
  yield takeLatest(
    downloadExpenseFileNumberTempTypeName,
    downloadExpenseFileNumberTempWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function*  downloadExpenseFileNumberTempWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.expensefilegenerate.downloadTemplate}`;
    const dwonload = yield call(NetworkService.get, url, {}, {
    });
    const linkSource = `data:text/csv;base64,${dwonload?.data}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = 'Expense_File_Number_Template';
    downloadLink.click();
  } catch (error) {}
}
  

  export function* fetchExpenseFileNumberWatcher() {
    yield takeLatest(
      fetchExpenseFileNumberTypeName,
      fetchExpenseFileNumberWatcherWorker,
    );
  }
  
  /**
   * Employee Details Worker
   */
  export function* fetchExpenseFileNumberWatcherWorker(action: any): any {
    try {
        let request = action.payload;
      const url = `${API_PATH.expensefilegenerate.saveusexpensefilegenerate}`;
      const res = yield call(NetworkService.post, url, request, {
      });
      yield put(
        expenseStateActions.setSaveRes(res?.data),
      );
    } catch (error) {}
  }
