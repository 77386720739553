import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  fetchReceivedAutoSuggestionTypeName,
  fetchReceivedReturnedItemsTypeName,
  fetchReceivedReturnedItemsName,
  receivedReturnedItemActions,
  fetchReceivedApprovedItemsTypeName,
  fetchReceivedRejectItemsTypeName,
} from './slice';

export function* fetchReceivedReturnedItemsAutoSuggestionWatcher() {
  yield takeLatest(
    fetchReceivedAutoSuggestionTypeName,
    fetchReceivedReturnedItemsAutoSuggestionsWorker,
  );
}
export function* fetchReceivedReturnedItemsAutoSuggestionsWorker(
  action: any,
): any {
  try {
    yield put(receivedReturnedItemActions.setInventoryAdminisReturnedLoading());
    if (action && action.payload) {
      const {searchData, toogleSwitch} = action.payload;
      const url = `${API_PATH.receivedReturnedItems.receivedAutoSuggestion}?isActive=${toogleSwitch}&searchData=${searchData}`;
      const autoSuggestionData = yield call(NetworkService.get, url, {}, null);
      if (autoSuggestionData?.status === 200) {
        yield put(
          receivedReturnedItemActions.setReturnedAutoSuggestionDataSuccess(
            autoSuggestionData?.data,
          ),
        );
      }
      yield put(receivedReturnedItemActions.setInventoryAdminReturnedError());
    }
  } catch (e) {
    yield put(receivedReturnedItemActions.setInventoryAdminReturnedError());
  }
}

export function* fetchReceivedFilteredDataWatcher() {
  yield takeLatest(
    fetchReceivedReturnedItemsTypeName,
    fetchReceivedFilteredDataWorker,
  );
}
export function* fetchReceivedFilteredDataWorker(action: any): any {
  try {
    yield put(receivedReturnedItemActions.setInventoryAdminisReturnedLoading());
    if (action && action.payload) {
      const {searchText, toogleSwitch, pageNumber} = action.payload;
      const url = `${API_PATH.receivedReturnedItems.receivedReturnedFilterData}?isActive=${toogleSwitch}&page=${pageNumber}&searchData=${searchText}`;
      const filteredData = yield call(NetworkService.get, url, {}, null);
      if (filteredData?.status === 200) {
        yield put(
          receivedReturnedItemActions.setReturnedFilteredData(
            filteredData?.data,
          ),
        );
      }
    }
  } catch (e) {
    yield put(receivedReturnedItemActions.setInventoryAdminReturnedError());
  }
}
export function* fetchReceivedReturnedItemsFilterWatcher() {
  yield takeLatest(
    fetchReceivedReturnedItemsName,
    fetchReceivedReturnedItemsFilterWorker,
  );
}

export function* fetchReceivedReturnedItemsFilterWorker(action: any): any {
  yield put(receivedReturnedItemActions.setInventoryAdminisReturnedLoading());
  try {
    const url = `${API_PATH.receivedReturnedItems.receivedReturnedItemsData}/${action.payload.isActive}?page=${action.payload.pageNumber}`;
    const res = yield call(NetworkService.post, url, action.payload, null);
    yield put(
      receivedReturnedItemActions.setInventoryAdminReturnedSuccess(res?.data),
    );
    yield put(receivedReturnedItemActions.getArReqApiCalled(true));
  } catch (e) {
    yield put(receivedReturnedItemActions.setInventoryAdminReturnedError());
  }
}

export function* fetchReceivedApprovedItemsWatcher() {
  yield takeLatest(
    fetchReceivedApprovedItemsTypeName,
    fetchReceivedApprovedItemsWorker,
  );
}

export function* fetchReceivedApprovedItemsWorker(action: any): any {
  const {toogleSwitch, pageNumber} = action.payload.getSearchData;
  yield put(receivedReturnedItemActions.setInventoryAdminisReturnedLoading());
  try {
    if (action && action.payload) {
      const url = `${API_PATH.receivedReturnedItems.receivedApproveRequest}`;
      const response = yield call(
        NetworkService.post,
        url,
        action?.payload?.newData,
        null,
      );

      if (action.payload.filterSearchDailog !== '') {
        yield put({
          type: fetchReceivedReturnedItemsTypeName,
          payload: {
            searchText: action?.payload?.filterSearchDailog,
            toogleSwitch,
            pageNumber,
          },
        });
      } else {
        yield put({
          type: fetchReceivedReturnedItemsName,
          payload: action?.payload?.req,
        });
      }

      if (response?.status === 200) {
        yield put(
          receivedReturnedItemActions.setInventoryAdminisReturnedLoading(),
        );
        yield put(
          receivedReturnedItemActions.setReceivedApprovedItems(response?.data),
        );
      }
    }
    yield put(receivedReturnedItemActions.setInventoryAdminisReturnedLoading());
  } catch (e) {
    yield put(receivedReturnedItemActions.setInventoryAdminReturnedError());
  }
}
export function* fetchReceivedRejectItemsWatcher() {
  yield takeLatest(
    fetchReceivedRejectItemsTypeName,
    fetchReceivedRejectItemsWorker,
  );
}
export function* fetchReceivedRejectItemsWorker(action: any): any {
  const {toogleSwitch, pageNumber} = action.payload.getSearchData;
  yield put(receivedReturnedItemActions.setInventoryAdminisReturnedLoading());
  try {
    if (action && action.payload) {
      const url = `${API_PATH.receivedReturnedItems.receivedRejectRequest}`;
      const response = yield call(
        NetworkService.post,
        url,
        action?.payload?.newData,
        null,
      );
      if (action.payload.filterSearchDailog !== '') {
        yield put({
          type: fetchReceivedReturnedItemsTypeName,
          payload: {
            searchText: action?.payload?.filterSearchDailog,
            toogleSwitch,
            pageNumber,
          },
        });
      } else {
        yield put({
          type: fetchReceivedReturnedItemsName,
          payload: action?.payload?.req,
        });
      }
      if (response?.status === 200) {
        yield put(
          receivedReturnedItemActions.setInventoryAdminisReturnedLoading(),
        );
        yield put(
          receivedReturnedItemActions.setReceivedRejectItems(response?.data),
        );
      }
    }
    yield put(receivedReturnedItemActions.setInventoryAdminisReturnedLoading());
  } catch (e) {
    yield put(receivedReturnedItemActions.setInventoryAdminReturnedError());
  }
}
